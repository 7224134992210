<template>
  <div  class="content ma-5 mb-16 px-0 px-sm-16  mx-sm-12" >

    <div class="text-h4 text-center ma-12">订单支付成功</div>

    <div class="ma-4 text-center">
      
        <v-btn  dark outlined  tile class="main-btn" @click="$router.push('/itemlist')">继续选购</v-btn>
        <v-btn  dark outlined  tile class="main-btn" @click="$router.push('/profile/myorder')">查看订单</v-btn>
    </div>

  </div>
</template>

<script>


import { mapState } from 'vuex'



export default {


  data: () => ({


    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.domain,
    }),
  },
  created() {

    

    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.tagbtn {
  font-weight: 800;
  font-size:17px;
  color:black;
  height: 27px !important;
} 
.main-btn {
  width:200px;
}

</style>

